// noinspection DuplicatedCode

'use strict'

import axios from 'axios'
import ReconnectingEventSource from 'reconnecting-eventsource'

(window as any).zoom = 0.6
    ; (window as any).dragging = false
    ; (window as any).dragData = null
    ; (window as any).dragTarget = null
    ; (window as any).previousCursor = null
    ; (window as any).preventInfos = false
    ; (window as any).hasModal = false
    ; (window as any).popover = null
const app = document.getElementById("app")
const map = document.getElementById("map")
// const infos = document.getElementById('infos')

document.addEventListener('DOMContentLoaded', async function () {
    const searchEvent = async function (ev) {
        ev.preventDefault()
        ev.stopPropagation()
        ev.stopImmediatePropagation()
        const streamers = []
        const search = (document.getElementById('search') as HTMLInputElement).value
        if (!search) return
        const res = await axios.get('/search/' + search)
        for (const streamer of res.data.streamers) {
            streamers.push(`<a href="javascript:;" class="search-item" data-name="${streamer.name}" data-position="${streamer.position}"><li class="py-2 px-4 w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">${streamer?.name}</li></a>`)
        }
        document.getElementById('search-results').style.display = 'block'
        document.getElementById('search-results').innerHTML = streamers.join('')
        document.querySelector('.search-item').addEventListener('click', function (ev: Event | any) {
            $('#' + this.getAttribute('data-position')).click()
            document.getElementById('search-results').style.display = 'none'
            document.getElementById('search-results').innerHTML = ''
        })
    }
    document.getElementById('search').addEventListener('keyup', async function (ev: Event | any) {
        if (ev.keyCode === 13) {
            await searchEvent(ev)
        }
    })
    document.getElementById('search-press').addEventListener('click', searchEvent)

    window.addEventListener('click', function (e: MouseEvent | any) {
        if (!document.getElementById('searchs').contains(e.target)) {
            document.getElementById('search-results').style.display = 'none'
            document.getElementById('search-results').innerHTML = ''
        }
    })

    const data = await axios.get('/ui/plan.svg')
    map.innerHTML = data.data

    const layer = document.getElementById('layer1')
    const elImage = document.getElementById('g30')
    layer.removeChild(elImage)
    layer.innerHTML = `<a href="https://zevent.fr/" target="_blank">${elImage.outerHTML}</a>` + layer.innerHTML

    await initES()

        ; (window as any).zoom = (window.innerWidth / window.innerHeight) / 2
    if (window.innerWidth < 960) (window as any).zoom -= 0.8
    if ((window as any).zoom < 0.4) (window as any).zoom = 0.4

    map.style.transform = `scale(${(window as any).zoom})`
    map.style.left = window.innerWidth / 2 - map.clientWidth / 2 + "px"

    const svgBg = document.getElementsByTagName("svg")[0].style.background.replace(/^rgb\(/, "").replace(/\)$/, "").replace(/\s/g, '').split(',')
    document.body.style.background = `
            repeating-linear-gradient(
              45deg,
              rgb(${svgBg}),
              rgb(${svgBg}) 10px,
              rgb(${svgBg}, 0.8) 10px,
              rgb(${svgBg}, 0.8) 20px
            )`
    map.innerHTML += `'<div id="player" style="background: rgb(${svgBg})"><h1>Un streameur choisi au hasard juste pour vous</h1></div>'`
    app.addEventListener('touchstart', startDrag, false)
    app.addEventListener('mousedown', startDrag, false)

    app.addEventListener('touchmove', drag, false)
    app.addEventListener('mousemove', drag, false)

    app.addEventListener('touchend', stopDrag, false)
    app.addEventListener('touchcancel', stopDrag, false)
    app.addEventListener('mouseup', stopDrag, false)
    app.addEventListener('mouseleave', stopDrag, false)
    app.addEventListener('mouseout', stopDrag, false)

    const rectEvent = async function (e) {
        e.preventDefault()
        // console.log('rectEvent', e)
        // e.stopPropagation()
        // e.stopImmediatePropagation()

        if (e.altKey && e.ctrlKey) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            stopDrag(e)

            const position = e.target.getAttribute('id')
            const streamer = prompt('Streamer login', e.target.getAttribute('data-name') || '')
            // const flowup = prompt('FlowUP', e.target.getAttribute('data-flowup') || '')

            try {
                const res = await axios.post('/api/infos', {
                    streamer,
                    flowup: '0',
                    position,
                })
                console.log('res', res)
            } catch (e) {
                if (e.response.status === 401) {
                    window.location.href = '/auth/twitch'
                }
            }
            return
        }

        if (typeof e.button !== 'undefined' && e.button !== 0) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            stopDrag(e)
            return
        }

        ; (document.querySelector('#popover-user-profile .avatar') as any).src = 'https://static-cdn.jtvnw.net/jtv_user_pictures/4217ef3b-ae2e-4132-b28c-80fbb6310ad2-profile_image-70x70.png'
        // ;(document.querySelector('#twitch-link') as any).href = 'javascript:;'
        // ;(document.querySelector('#twitch-url') as any).href = 'javascript:;'
        // ;(document.querySelector('#twitch-url') as any).innerHTML = ''
        // ;(document.querySelector('#channel-name') as any).innerHTML = ''
        // ;(document.querySelector('#channel-description') as any).innerHTML = ''

        try {
            const streamer = await axios.get('/infos/' + this.id)
            if (streamer.data.streamer.avatar) {
                ; (document.querySelector('#popover-user-profile .avatar') as any).src = streamer.data.streamer.avatar
                    ; (document.querySelector('#popover-user-profile .avatar') as any).style.border = '6px solid ' + this.style['fill']
            }
            ; (document.querySelector('#twitch-url') as any).href = 'https://zevent.fr/stream/' + streamer.data.streamer.name
            if (this.getAttribute('data-viewer_count') > 0) {
                ; (document.querySelector('#view_count') as any).innerHTML = this.getAttribute('data-viewer_count')
                    ; (document.querySelector('#channel-view_count') as any).style.display = 'block'
            } else {
                ; (document.querySelector('#channel-view_count') as any).style.display = 'none'
            }

            const title = this.getAttribute('data-title')
                ; (document.querySelector('#channel-name') as any).innerHTML = streamer.data.streamer.profile.displayName
                // ; (document.querySelector('#has-flowup') as any).style.display = streamer.data.streamer?.profile?.flowup ? 'block' : 'none'
                // this.setAttribute('data-flowup', streamer.data.streamer?.profile?.flowup ? '1' : '0')
                ; (document.querySelector('#channel-cagnotte') as any).href = '/don/' + streamer.data.streamer.profile.login
                ; (document.querySelector('#channel-don-link') as any).href = '/don/' + streamer.data.streamer.profile.login
                ; (document.querySelector('#channel-title') as any).innerHTML = title !== 'undefined' ? title : 'hors ligne'
                ; (document.querySelector('#channel-money') as any).innerHTML = (streamer.data.bank || 0 / 100)
            // ; (document.querySelector('#channel-labo') as any).href = 'https://labo.telethongaming.tacxtv.fr/ui/?visite=' + streamer.data.streamer.name

            // @ts-ignore
            window.popover = new Popover(document.getElementById('popover-user-profile'), this, {
                placement: 'top',
                triggerType: 'click',
                offset: 10,
            })
                ; (window as any).popover.show()
        } catch (e) {
            console.error('unable to get streamer infos', e)
        }
    }

    app.addEventListener('wheel', function (e) {
        // e.preventDefault()
        e.stopPropagation()
            ; (window as any).zoom += -e.deltaY / 1000
        // app.style['transform-origin'] = 'center center'
        if ((window as any).zoom < 0.3) (window as any).zoom = 0.3
        if ((window as any).zoom > 2.5) (window as any).zoom = 2.5
        map.style.transform = `scale(${(window as any).zoom})`

        if ((window as any).popover) {
            ; (window as any).popover.hide()
                ; (window as any).popover = null
            document.querySelectorAll('rect').forEach((rect) => {
                rect.removeEventListener('click', rectEvent)
            })
            return
        }
    }, false)

    document.querySelectorAll('rect').forEach((rect) => {
        rect.style.cursor = 'pointer'
        // rect.addEventListener('mouseup', rectEvent)
        // rect.addEventListener('click', rectEvent)
        $('#' + rect.id).on('click', rectEvent)
        rect.addEventListener('touchend', rectEvent)
        //     if (!(window as any).dragData) {
        //         ;(window as any).preventInfos = true
        //         ;(window as any).hasModal = true
        //
        //
        //             return
        //
        //         ;(document.querySelector('#infos .avatar') as any).src = 'https://static-cdn.jtvnw.net/jtv_user_pictures/4217ef3b-ae2e-4132-b28c-80fbb6310ad2-profile_image-70x70.png'
        //         ;(document.querySelector('#channel-name') as any).innerHTML = ''
        //         ;(document.querySelector('#channel-description') as any).innerHTML = ''
        //
        //         try {
        //             const streamer = await axios.get('/infos/' + rect.id)
        //             if (streamer.data.streamer.avatar) (document.querySelector('#infos .avatar') as any).src = streamer.data.streamer.avatar
        //             ;(document.querySelector('#infos #twitch-link') as any).href = 'https://twitch.tv/' + streamer.data.streamer.name
        //
        //             ;(document.querySelector('#channel-name') as any).innerHTML = streamer.data.streamer.profile.displayName
        //             ;(document.querySelector('#channel-description') as any).innerHTML = streamer.data.streamer.profile.description
        //
        //             infos.style.display = 'block'
        //             infos.style.left = e.clientX + 30 + 'px'
        //             infos.style.top = e.clientY - infos.offsetHeight / 2 + 'px'
        //         } catch (e) {
        //             console.error('unable to get streamer infos', e)
        //         }
        //     }
        // })
    })

    // window.addEventListener('click', function (e) {
    //     console.log('click')
    //     // @ts-ignore
    //     if (!document.getElementById('popover-user-profile').contains(e.target) && !(window as any).preventInfos) {
    //         infos.style.display = 'none'
    //         ;(window as any).hasModal = false
    //         if ((window as any).popover) {
    //             ;(window as any).popover.hide()
    //             ;(window as any).popover = null
    //         }
    //     }
    //     (window as any).preventInfos = false
    //
    // })

    function startDrag(e) {
        // e.preventDefault()
        // e.stopPropagation()
        // e.stopImmediatePropagation()
        ; (window as any).dragging = true
        if ((window as any).popover && !document.getElementById('popover-user-profile').contains(e.target)) {
            ; (window as any).popover.hide()
                ; (window as any).popover = null
            document.querySelectorAll('rect').forEach((rect) => {
                rect.removeEventListener('click', rectEvent)
            })
            return
        }
    }

    function drag(e) {
        if ((window as any).dragging) {
            ; (window as any).dragTarget = e.target
                ; (window as any).previousCursor = e.target.style.cursor
            e.target.style.cursor = 'grabbing'

            let x = e.clientX
            let y = e.clientY

            if (!x) x = e.touches[0] ? e.touches[0].clientX : 0
            if (!y) y = e.touches[0] ? e.touches[0].clientY : 0;

            (window as any).dragData = {
                x: x - map.offsetLeft,
                y: y - map.offsetTop
            }
                ; (window as any).dragging = false
            map.style.cursor = 'grabbing'
        }

        if ((window as any).dragData) {
            // infos.style.display = 'none'
            move(e)
        }
    }

    // noinspection JSUnusedLocalSymbols
    function stopDrag(e) {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()
        if ((window as any).dragTarget) (window as any).dragTarget.style.cursor = (window as any).previousCursor;

        (window as any).previousCursor = null
            ; (window as any).dragTarget = null

        if ((window as any).dragData) {
            map.style.cursor = 'grab'
                // move(e)
                ; (window as any).dragData = null
        }
        ; (window as any).dragging = false
    }

    function move(e) {
        let x = e.clientX
        let y = e.clientY

        if (!x) x = e.touches[0] ? e.touches[0].clientX : 0
        if (!x) x = e.touches[0] ? e.changedTouches[0].clientX : 0
        if (!y) y = e.touches[0] ? e.touches[0].clientY : 0
        if (!y) y = e.touches[0] ? e.changedTouches[0].clientY : 0

        map.style.left = x - (window as any).dragData.x + "px"
        map.style.top = y - (window as any).dragData.y + "px"
    }

    async function initES() {
        await onlines()
        const es = new ReconnectingEventSource('/stream')
        es.addEventListener('onlines', async (e) => {
            try {
                const data = JSON.parse(e.data)
                const elCount = document.querySelector('#counter tspan')
                elCount.setAttribute('data-count', '0')
                elCount.innerHTML = ''

                for (const online of data.onlines) {
                    const prevPos = document.querySelector('rect[data-name="' + online.name + '"]')
                    if (prevPos && online.position !== prevPos.id) {
                        prevPos.setAttribute('data-name', '')
                        // @ts-ignore
                        prevPos.style.fill = 'rgb(255, 255, 255)'
                        // @ts-ignore
                        prevPos.style['fill-opacity'] = 0.1
                    }
                    const el = document.getElementById(online.position)
                    if (!el) continue
                    const totalCount = parseInt(elCount?.getAttribute('data-count') || '0', 10) || 0
                    elCount?.setAttribute('data-count', totalCount + (online?.online?.viewer_count || 0))
                    elCount.innerHTML = (totalCount + (online?.online?.viewer_count || 0)) + ' viewers au total'
                    el.setAttribute('data-name', online.name)
                    el.setAttribute('data-title', online?.online?.title)
                    el.setAttribute('data-viewer_count', online?.online?.viewer_count || 0)
                    el.style.fill = online.online ? '#00a900' : '#ff0000'
                    el.style['fill-opacity'] = 1
                }
            } catch (e) {
                console.error('unable to get streamer infos', e)
            }
        })
    }

    async function onlines() {
        const onlines = await axios.get('/online')
        const elCount = document.querySelector('#counter tspan')
        elCount.setAttribute('data-count', '0')
        elCount.innerHTML = ''
        setPlayer(onlines?.data?.random)
        for (const online of onlines.data.onlines) {
            if (!online.position) continue
            const el = document.getElementById(online.position)
            if (!el) continue
            el.setAttribute('data-name', online.name)
            el.setAttribute('data-title', online?.online?.title)
            el.setAttribute('data-bank', online?.bank)
            el.setAttribute('data-viewer_count', online?.online?.viewer_count || 0)
            el.style.fill = online.online ? '#00a900' : '#ff0000'
            el.style['fill-opacity'] = 1

            const totalCount = parseInt(elCount?.getAttribute('data-count') || '0', 10) || 0
            elCount?.setAttribute('data-count', totalCount + (online?.online?.viewer_count || 0))
            elCount.innerHTML = (totalCount + (online?.online?.viewer_count || 0)) + ' viewers au total'
        }
    }

    const step = 100

        ; (window as any).moveLeft = () => {
            map.style.left = map.offsetLeft + step + "px"
        }

    (window as any).moveUp = () => {
        map.style.top = map.offsetTop + step + "px"
    }

    (window as any).moveRight = () => {
        map.style.left = map.offsetLeft - step + "px"
    }

    (window as any).moveDown = () => {
        map.style.top = map.offsetTop - step + "px"
    }

    function setPlayer(random) {
        if (!random) return

        setTimeout(() => {
            let player
            const options = {
                // width: 1024,
                // height: 768,
                layout: 'video',
                channel: random,
                playsinline: false,
                // muted: true,
            }

            player = new window.Twitch.Embed(document.getElementById('player'), options)
            // player.addEventListener('ended', () => this.$emit('ended'))
            // player.addEventListener('pause', () => this.$emit('pause'))
            // player.addEventListener('play', () => this.$emit('play'))
            // player.addEventListener('offline', () => this.$emit('offline'))
            // player.addEventListener('online', () => this.$emit('online'))
            player.addEventListener('ready', () => {
                player.setQuality('medium')
                player.setVolume(0)
            })
        }, 1000)
    }
})
